import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'


const NotFoundPage = props => (
  <Layout color='bg-indigo-800'>
    <SEO title='404: Not found' />
    <div className='py-40 container mx-auto max-w-3xl px-6 sm:px-8 lg:px-8 text-center'>
      <div className='title'><h1>Page Not Found</h1></div>
      <p className='font-semibold text-6xl'>404</p>
      <p>But don't be sad, this is no dead-end.</p><Link to='/' className='button bg-white mt-4' href='/'>Take me home</Link>
    </div>
  </Layout>
)


export default NotFoundPage
